if ("serviceWorker" in navigator) {
  var installButton = document.getElementById("install-button");
  // Check for iOS standalone mode
  if ("standalone" in navigator && navigator.standalone) {
    installButton.style.display = "none"; // Hide the install button
  } else if (window.matchMedia("(display-mode: standalone)").matches) {
    installButton.style.display = "none"; // Hide the install button
  } else {
    // Store the PWA installation prompt
    var deferredPrompt;
    window.addEventListener("beforeinstallprompt", function (event) {
      // Prevent the default prompt behavior
      event.preventDefault();

      // Store the event for later use
      deferredPrompt = event;

      installButton.style.display = "block"; // Show the install button

      installButton.addEventListener("click", function () {
        // Prompt the user to install the PWA
        deferredPrompt.prompt();

        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then(function (choiceResult) {
          if (choiceResult.outcome === "accepted") {
            installButton.style.display = "none";
          } else {
            installButton.style.display = "block";
          }
        });
        // Clear the deferred prompt
        deferredPrompt = null;
      });
    });
  }
}
